import React, { type ReactNode } from 'react';
import {
  Footer as HungryrootFooter,
  AppBadgeVariant,
} from '@hungryroot/footer';
import halfDishLogo from '../../../public/logos/half_dish_logo.svg';
import googlePlayLogo from '../../images/google_play_banner.png';

export function Footer({
  showBorder,
  children,
}: {
  showBorder?: boolean;
  children: ReactNode;
}) {
  return (
    <HungryrootFooter
      customerLinks={children}
      mhrImage={
        <a href="/" data-testid="footer-hungryroot-logo">
          <HungryrootFooter.Logo src={halfDishLogo.src} />
        </a>
      }
      appleAppStoreBadge={
        <a href="https://hungryroot.onelink.me/ZIxn/3x3w5qer" target="_blank">
          <HungryrootFooter.AppBadge variant={AppBadgeVariant.Apple} />
        </a>
      }
      googlePlayBadge={
        <a href="https://get.hungryroot.com/3RLzla7" target="_blank">
          <HungryrootFooter.AppBadge
            src={googlePlayLogo.src}
            alt="Google Play Badge"
            variant={AppBadgeVariant.Google}
          />
        </a>
      }
      showBorder={showBorder}
    />
  );
}
